import request from '@/utils/request'


// 查询小区锁门禁卡 列表
export function listCard(query) {
  return request({
    url: '/user/village-cards/list',
    method: 'get',
    params: query
  })
}

// 查询小区锁门禁卡 分页
export function pageCard(query) {
  return request({
    url: '/user/village-cards/page',
    method: 'get',
    params: query
  })
}

// 查询小区锁门禁卡 详细
export function getCard(data) {
  return request({
    url: '/user/village-cards/detail',
    method: 'get',
    params: data
  })
}

// 新增小区锁门禁卡
export function addCard(data) {
  return request({
    url: '/user/village-cards/add',
    method: 'post',
    data: data
  })
}

// 修改小区锁门禁卡
export function updateCard(data) {
  return request({
    url: '/user/village-cards/edit',
    method: 'post',
    data: data
  })
}

// 删除小区锁门禁卡
export function delCard(data) {
  return request({
    url: '/user/village-cards/delete',
    method: 'post',
    data: data
  })
}
