<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="小区" prop="villageId" >
<!--        <a-input v-model="form.villageId" placeholder="请输入小区Id,zb_village.id" />-->
        <a-select
          v-model="form.villageId"
          showSearch
          style="width: 100%"
          placeholder="请选择"
          allow-clear
          @change='changeVillage'
          optionFilterProp="children"
        >
          <a-select-option :value="item.id" :key="item.id" :title="item.title" v-for="(item,index) in villageAll">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="楼栋" prop="buildingId" >
        <a-select
          v-model="form.buildingId"
          showSearch
          style="width: 100%"
          placeholder="请选择"
          allow-clear
          @change='changeBuilding'
          optionFilterProp="children"
        >
          <a-select-option :value="item.id" :key="item.id" :title="item.title" v-for="(item,index) in buildingAll">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="单元" prop="unitId" >
        <a-select
          v-model="form.unitId"
          showSearch
          style="width: 100%"
          placeholder="请选择"
          allow-clear
          @change='changeUnit'
          optionFilterProp="children"
        >
          <a-select-option :value="item.id" :key="item.id" :title="item.title" v-for="(item,index) in unitAll">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="房间" prop="roomId" >
<!--        <a-input v-model="form.roomId" placeholder="请输入房间id，zb_village_room.id" />-->
        <a-select
          v-model="form.roomId"
          showSearch
          style="width: 100%"
          placeholder="请选择"
          allow-clear

          optionFilterProp="children"
        >
          <a-select-option :value="item.id" :key="item.id" :title="item.title" v-for="(item,index) in roomAll">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item label="用户id，zb_user.id" prop="userId" >-->
<!--        <a-input v-model="form.userId" placeholder="请输入用户id，zb_user.id" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="卡号" prop="cardNum" >
        <a-input v-model="form.cardNum" placeholder="请输入卡号" />
      </a-form-model-item>
      <a-form-model-item label="用户名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入用户名称" />
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="mobile" >
        <a-input v-model="form.mobile" placeholder="请输入手机号" />
      </a-form-model-item>
      <a-form-model-item label="身份证号" prop="idCard" >
        <a-input v-model="form.idCard" placeholder="请输入身份证号" />
      </a-form-model-item>
      <a-form-model-item label="卡类型" prop="type" >
        <a-select v-model="form.type" placeholder="请选择 " style="width: 100%" allow-clear>
          <a-select-option :value="0">普通卡</a-select-option>
          <a-select-option :value="1">超级卡</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCard, addCard, updateCard } from '@/api/village/card'

import {listVillage} from '@/api/village/village'
import {listBuilding} from '@/api/village/building'
import {listUnit} from '@/api/village/unit'
import {listRoom} from '@/api/village/room'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      villageAll: [],
      buildingAll:[],
      unitAll:[],
      roomAll:[],
      // 表单参数
      form: {
        id: null,

        villageId: null,

        buildingId: null,

        unitId: null,

        roomId: null,

        userId: null,

        cardNum: null,

        name: null,

        mobile: null,

        idCard: null,

        type: null,

        status: 0,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        villageId: [
          { required: true, message: '小区不能为空', trigger: 'blur' }
        ],
        buildingId: [
          { required: true, message: '楼栋不能为空', trigger: 'blur' }
        ],
        unitId: [
          { required: true, message: '单元不能为空', trigger: 'blur' }
        ],
        roomId: [
          { required: true, message: '房间不能为空', trigger: 'blur' }
        ],
        cardNum: [
          { required: true, message: '卡号不能为空', trigger: 'blur' }
        ]

      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    changeVillage(value){
      console.log("====",value)
      this.buildingList(value)
      this.form.buildingId = null;
      this.form.unitId = null;
      this.form.roomId = null;
    },
    changeBuilding(value){
      console.log("====",value)
      this.unitList(value)
      this.form.unitId = null;
      this.form.roomId = null;
    },
    changeUnit(value){
      console.log("====",value)
      this.roomList(value)
      this.form.roomId = null;
    },
    villageList(){
      listVillage({}).then(res => {
        this.villageAll = res.data;
      })
    },
    buildingList(villageId){
      listBuilding({"villageId":villageId}).then(res => {
        this.buildingAll = res.data;
        // this.form.buildingId = null;
      })
    },
    unitList(buildingId){
      listUnit({"villageId":this.form.villageId,"buildingId":buildingId}).then(res => {
        this.unitAll = res.data;
        // this.form.unitId = null;
      })
    },
    roomList(unitId){
      listRoom({"villageId":this.form.villageId,"buildingId":this.form.buildingId,"unitId":unitId}).then(res => {
        this.roomAll = res.data;
        // this.form.roomId = null;
      })
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.villageAll = [],
        this.buildingAll = [],
        this.unitAll = [],
        this.roomAll = [],
      this.form = {
        id: null,
        villageId: null,
        buildingId: null,
        unitId: null,
        roomId: null,
        userId: null,
        cardNum: null,
        name: null,
        mobile: null,
        idCard: null,
        type: null,
        status: 0,
        createTime: null,
        remark: null
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.villageList();
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCard({"id":id}).then(response => {
        console.log(response.data)
        console.log("==========")
        this.formTitle = '修改'
        this.villageList();
        this.buildingList(response.data.villageId);
        this.unitList(response.data.buildingId);
        this.roomList(response.data.unitId)
        this.form = response.data
        console.log(this.form)


        this.open = true
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateCard(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addCard(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
